<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <container ref="container">
            <!--0：全部；10：待付款；20：待发货；30：已发货；99：已取消；100：已完成-->
            <order-status :data="order" />
            <!--<div class="order-status bc-main" v-if="+order.orderStatus===10"><i class="f-icon fs-super">&#xf050;</i>{{order.orderStatusName}}</div>
            <div class="order-status bc-yellow" v-if="+order.orderStatus===20"><i class="f-icon fs-super">&#xf051;</i>{{order.orderStatusName}}</div>
            <div class="order-status bc-blue" v-if="+order.orderStatus===30"><i class="f-icon fs-super">&#xf052;</i>{{order.orderStatusName}}</div>
            <div class="order-status bc-g5" v-if="+order.orderStatus===99"><i class="f-icon fs-super">&#xf054;</i>{{order.orderStatusName}}</div>
            <div class="order-status bc-green" v-if="+order.orderStatus===100"><i class="f-icon fs-super">&#xf053;</i>{{order.orderStatusName}}</div>-->

            <TrackingItem
                class="ma-bor-b"
                v-if="isTracking"
                :data="order"
                :to="`/order/tracking/${order.orderSn}`"
            />

            <div
                class="pa-a bc-w"
                v-if="orderStatus===15"
            >
                <JoinUser :data="groupBuyData.groupBuyDetail" />
                <linker
                    :to="`/goods/groupBuy/${order.id}`"
                    class="btn pa-a radius btn-main fill"
                >
                    邀请好友参团
                </linker>
            </div>

            <list class="ma-b">
                <OrderGoodsItem
                    v-for="(item,index) in order.goodsList"
                    :key="index"
                    :data="item"
                />
                <list-item label="商品金额: ">
                    <div
                        class="price extra"
                        slot="extra"
                    >
                        {{ order.goodsAmount | price }}
                    </div>
                </list-item>
                <list-item
                    v-if="order.goodsPremiumAmount"
                    label="物流保价："
                >
                    <div
                        class="extra price"
                        slot="extra"
                    >
                        {{ order.goodsPremiumAmount | price }}
                    </div>
                </list-item>
                <list-item label="商品运费: ">
                    <div
                        class="price extra"
                        slot="extra"
                        v-if="order.freight"
                    >
                        {{ order.freight | price }}
                    </div>
                    <div
                        class="extra tc-g6"
                        slot="extra"
                        v-else
                    >
                        免运费
                    </div>
                </list-item>
                <list-item
                    v-if="order.couponAmount"
                    label="优惠券"
                >
                    <div
                        class="extra tc-price"
                        slot="extra"
                    >
                        - <span class="price">{{ order.couponAmount | price }}</span>
                    </div>
                </list-item>
                <list-item
                    v-if="order.giftCardAmount"
                    label="礼品卡金额"
                >
                    <div
                        class="extra tc-price"
                        slot="extra"
                    >
                        <span class="price">{{ order.giftCardAmount | price }}</span>
                    </div>
                </list-item>
                <list-item
                    v-if="order.reduceAmount"
                    label="立减"
                >
                    <div
                        class="extra tc-price"
                        slot="extra"
                    >
                        - <span class="price">{{ order.reduceAmount | price }}</span>
                    </div>
                </list-item>
                <list-item label="订单合计: ">
                    <div
                        class="price extra"
                        slot="extra"
                    >
                        {{ order.payableAmount | price }}
                    </div>
                </list-item>
            </list>

            <template v-if="order.goodsSendMode === 4">
                <list class="ma-b">
                    <list-item
                        label="付款人姓名："
                        :extra="order.subscriberName"
                    />
                    <list-item
                        label="付款人身份证号："
                        :extra="order.subscriberIdCardNo"
                    />
                </list>
            </template>

            <template
                v-if="order.shipMethod===1"
            >
                <div class="address-box ma-b">
                    <list>
                        <list-item
                            label="姓名："
                            :extra="order.deliveryConsignee"
                        />
                        <list-item
                            label="手机："
                            :extra="order.deliveryPhone"
                        />
                        <list-item
                            label="地址："
                            :extra="`${order.deliveryRegionName} ${order.deliveryAddress}`"
                        />
                        <list-item
                            label="备注："
                            :extra="order.buyerMsg"
                        />
                    </list>
                </div>
            </template>
            <template v-if="order.shipMethod===2">
                <list class="ma-b">
                    <list-item
                        label="门店名称："
                    >
                        <div
                            class="extra"
                            slot="extra"
                        >
                            <div>{{ order.pickupStoreName }}</div>
                        </div>
                    </list-item>
                    <list-item
                        label="门店地址："
                    >
                        <div
                            class="extra"
                            slot="extra"
                        >
                            <div>{{ order.pickupStoreRegionName }} {{ order.pickupStoreDetailAddress }}</div>
                        </div>
                    </list-item>
                    <list-item
                        label="门店联系人姓名："
                    >
                        <div
                            class="extra"
                            slot="extra"
                        >
                            <div>{{ order.pickupContactFullname }}</div>
                        </div>
                    </list-item>
                    <list-item
                        label="门店联系人电话："
                    >
                        <div
                            class="extra"
                            slot="extra"
                        >
                            <div>{{ order.pickupContactTel }}</div>
                        </div>
                    </list-item>
                    <list-item
                        label="备注："
                        :extra="order.buyerMsg"
                    />
                </list>
            </template>

            <list class="ma-b">
                <div class="bar-code">
                    <svg ref="barCode" />
                </div>
                <list-item
                    label="订单号："
                    :extra="order.orderSn"
                />
                <list-item
                    label="下单时间："
                    :extra="order.orderTime|dateTime"
                />
            </list>
        </container>
        <div
            class="footer"
            v-if="isEditAddress || isCancelOrder || orderStatus === 30 || order.paymentMode === 2"
        >
            <OrderEditAddress
                class="btn btn-green"
                :data="order"
                v-if="isEditAddress"
            />
            <template
                v-if="isCancelOrder"
            >
                <div
                    v-if="orderStatus===10 && order.paymentStatus === 50"
                    class="btn btn-red"
                    @click.stop="onCancelOrder"
                >
                    取消订单
                </div>
                <refund-popup
                    :order-id="order.id"
                    v-if="order.isCanRefund"
                    class="btn btn-red"
                >
                    取消订单
                </refund-popup>
            </template>
            <pay-popup
                class="btn btn-green"
                v-if="orderStatus===10 && order.paymentStatus === 50 &&($globalVar.device.isWeiXin || !$globalVar.device.isMobile)"
                :order-ids="order.id"
                :module="1"
            >
                立即支付
            </pay-popup>
            <linker
                class="btn btn-second"
                v-if="order.paymentMode===2"
                :to="`/order/offlineDetail/${order.id}`"
            >
                付款详情
            </linker>
            <div
                v-if="orderStatus === 30"
                @click.stop="onCompleteOrder"
                class="btn btn-green"
            >
                确认收货
            </div>
        </div>
    </Page>
</template>

<script>
import JsBarcode from 'jsbarcode';
import { Buyer } from '@/api';
import pageMixin from '@/mixin/layout/page';
import orderStatus from './_orderStatus';

export default {
    name: 'OrderDetails',
    mixins: [pageMixin],
    components: { orderStatus },
    data() {
        return {
            order: {},
            groupBuyData: {},
        };
    },
    beforeRouteEnter(to, from, next) {
        const orderId = to.params.id;
        Buyer.Ex.Order.orderDetail({ orderId })
            .then(orderJson => {
                const order = orderJson.data.data;
                if (order.orderStatus === 15) {
                    const { groupBuyId } = order;
                    Buyer.Ex.GroupBuy.groupBuyDetail({ id: groupBuyId }).then(json => {
                        const groupBuyData = json.data.data;
                        next(vm => {
                            vm.order = order;
                            vm.groupBuyData = groupBuyData;
                        });
                    });
                } else {
                    next(vm => {
                        vm.order = order;
                    });
                }
            })
            .catch(() => {
                console.log(234234);
                next();
            });
    },
    computed: {
        id() {
            return this.$route.params.id;
        },
        orderStatus() {
            return +this.order.orderStatus;
        },
        orderSn() {
            return this.order.orderSn || '';
        },
        isCancelOrder() {
            return !!((this.orderStatus === 10 && this.order.paymentStatus === 50) || this.order.isCanRefund);
        },
        isEditAddress() {
            return this.order.shipMethod === 1 && this.orderStatus > 0 && this.orderStatus < 30;
        },
        isTracking() {
            return (
                (this.order.orderStatus === 20 || this.order.orderStatus === 30 || this.order.orderStatus === 100) &&
                this.order.shipMethod + '' === '1'
            );
        },
    },
    watch: {
        orderSn(val) {
            if (!val) return;
            console.log(val);
            JsBarcode(this.$refs['barCode'], val, {
                // format: 'pharmacode',
                // lineColor: '#0aa',
                width: 5,
                height: 100,
                displayValue: false,
            });
        },
    },
    methods: {
        getOrderDetails(id = this.id) {
            this.$api.Buyer.Ex.Order.orderDetail({
                orderId: id,
            }).then(json => {
                const res = json.data;
                this.order = res.data;
            });
        },
        onCompleteOrder() {
            this.$messageBox.confirm('是否要确认该订单', '温馨提示').then(() => {
                this.$api.Buyer.Ex.Order.completeOrder({
                    orderId: this.order.id,
                }).then(json => {
                    const res = json.data;
                    // res.data.payableAmount = 999.99;
                    // res.data.orderStatus = 31;
                    this.order = res.data;
                    this.$messageBox.alert(res.msg, '温馨提示');
                });
            });
        },
        onCancelOrder() {
            this.$messageBox
                .confirm('确定要取消该订单?', '温馨提示', {
                    cancelBtnText: '我再想想',
                    confirmBtnText: '确定取消',
                })
                .then(() => {
                    this.$api.Buyer.Ex.Order.cancelOrder({
                        orderId: this.order.id,
                    }).then(json => {
                        const res = json.data;
                        // res.data.payableAmount = 999.99;
                        // res.data.orderStatus = 31;
                        this.order = res.data;
                        this.$messageBox.alert(res.msg, '温馨提示');
                    });
                });
        },
    },
    mounted() {
        // this.getOrderDetails();
    },
};
</script>

<style lang="scss">
.bar-code {
    padding: $padding;
    line-height: 0;
    background-color: #fff;
    > svg {
        width: 100%;
        height: auto;
    }
}
</style>
