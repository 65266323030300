<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <div class="second-header">
            <tabs @tabChange="onTabsChange">
                <template v-for="(item,index) in orderStatusMap">
                    <tab-panel
                        v-if="!(!$hasPower(['groupBuy:use']) && +item.status === 15)"
                        :key="index"
                        :label="item.name"
                        :sign="item.status"
                        :active-panel="item.status+'' === orderStatus+''"
                    >
                        <div slot="nav">
                            <i
                                class="f-icon"
                                v-if="+item.status === 10"
                            >&#xf050;</i>
                            <i
                                class="f-icon"
                                v-if="+item.status === 20"
                            >&#xf051;</i>
                            <i
                                class="f-icon"
                                v-if="+item.status === 30"
                            >&#xf052;</i>
                            <i
                                class="f-icon"
                                v-if="+item.status === 99"
                            >&#xf054;</i>
                            <i
                                class="f-icon"
                                v-if="+item.status === 100"
                            >&#xf053;</i>
                            {{ item.name }}
                        </div>
                    </tab-panel>
                </template>
            </tabs>
        </div>
        <query-box
            class="second-header"
            top=".89rem"
            placeholder="请输入姓名或手机号"
            show-search-btn
            show-reset-btn
            v-model="keywords"
            @query="onSearch"
            @reset="onReset"
        >
            <!--<x-form
                ref="form"
                :model="formData"
            >
                <form-item
                    label="姓名:"
                    prop="name"
                >
                    <x-input v-model="formData.name"/>
                </form-item>
                <form-item
                    label="手机:"
                    prop="mobile"
                >
                    <x-input v-model="formData.mobile"/>
                </form-item>
            </x-form>-->
        </query-box>
        <container ref="container">
            <list>
                <order-item
                    v-for="(item,index) in orderList"
                    :key="item.id"
                    :data="item"
                    v-model="orderIds"
                    @delete="onItemDelete(item,index)"
                />
            </list>
            <infinite-loading
                ref="infinite"
                :disabled="infiniteDisabled"
                @infinite="onInfinite"
            />
        </container>
        <div
            class="second-footer"
            v-if="$hasPower(['orderBatchPay:use'])&&(orderIds.length||(orderList.length&&orderStatus===10))"
        >
            <div>
                <checkbox
                    v-if="orderStatus===10"
                    v-model="checkedAll"
                    @input="onCheckedAllChange"
                >
                    全选
                </checkbox>
            </div>
            <pay-popup
                class="btn btn-main"
                v-if="orderIds.length"
                :order-ids="orderIdsStr"
                lazy
                :module="1"
            >
                合并付款({{ orderIds.length }})
            </pay-popup>
            <div
                class="btn btn-g3"
                v-else
            >
                合并付款
            </div>
        </div>
        <template v-if="pagination.currentPage">
            <div
                v-if="!$globalVar.device.isWeiXin && orderList && orderList.length"
                class="footer"
            >
                <linker
                    :to="isDistributor?`/distributor/home`:`/`"
                    class="btn tc-g6"
                    style="flex: 0 0 auto;width: auto;"
                >
                    <div>
                        <i class="f-icon fs-more">&#Xf006;</i>
                        <div class="fs-small">
                            首页
                        </div>
                    </div>
                </linker>
                <div
                    class="btn btn-main"
                    @click="onExportOrder"
                >
                    导出订单
                </div>
            </div>
            <div
                v-else
                class="footer"
            >
                <linker
                    :to="isDistributor?`/distributor/home`:`/`"
                    class="btn btn-main fs-big"
                >
                    <i class="f-icon">&#Xf006;</i>首页
                </linker>
            </div>
        </template>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
import infiniteLoading from '@/mixin/infiniteLoading';

export default {
    name: 'OrderList',
    mixins: [pageMixin, infiniteLoading],
    data() {
        return {
            orderList: [],
            orderIds: [],
            checkedAll: false,
            // 0：全部；10：待付款；20：待发货；30：已发货；99：已取消；100：已完成
            orderStatusMap: [
                {
                    status: 0,
                    name: '全部',
                },
                {
                    status: 10,
                    name: '待付款',
                },
                {
                    status: 15,
                    name: '待拼团',
                },
                {
                    status: 20,
                    name: '待发货',
                },
                {
                    status: 30,
                    name: '待收货',
                },
                {
                    status: 100,
                    name: '已完成',
                },
                {
                    status: 99,
                    name: '已取消',
                },
            ],
            keywords: '',
            formData: {
                name: '',
                mobile: '',
            },
        };
    },
    computed: {
        orderStatus() {
            return +this.$route.params.orderStatus || 0;
        },
        orderIdsStr() {
            return this.orderIds.join(',');
        },
    },
    beforeRouteEnter(to, from, next) {
        if (to.params.orderStatus === undefined || to.params.orderStatus === '') {
            next({
                // replace: true,
                path: `${to.path}/0`,
                query: {
                    ...to.query,
                },
            });
            return;
        }
        next();
    },
    methods: {
        getListData({ orderStatus = this.orderStatus, searchKeyword = this.keywords, currentPage, pageSize } = {}) {
            return this.$api.Buyer.Ex.Order.orderList({
                orderStatus,
                searchKeyword,
                currentPage: currentPage || this.pagination.currentPage ? this.pagination.currentPage + 1 : 1,
                pageSize,
            }).then(json => {
                const res = json.data;
                this.orderList = this.orderList.concat(res.data);
                return Promise.resolve(json);
            });
        },
        onTabsChange(item) {
            this.$router.replace({
                path: `/order/list/${item.sign}`,
                query: {
                    ...this.$router.query,
                },
            });
        },
        onItemDelete(item, index) {
            this.orderList.splice(index, 1);
        },
        onSearch() {
            this.infiniteDisabled = true;
            this.orderList = [];
            this.orderIds = [];
            this.pagination = {};
            this.$nextTick(() => {
                this.infiniteDisabled = false;
            });
        },
        onReset() {
            this.$$form.resetFields();
            this.$$form.clearValidate();
        },
        onCheckedAllChange(val) {
            if (val) {
                this.$api.Buyer.Ex.Order.getOrderPayInfo().then(json => {
                    const res = json.data.data;
                    this.orderIds = res.orderIdList;
                });
            } else {
                this.orderIds.splice(0, this.orderIds.length);
            }
        },
        onExportOrder() {
            this.$http.download('/rpc/buyer/ex/order/exportOrderExcel', {
                searchKeyword: this.keywords,
                orderStatus: this.orderStatus,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.second-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding-left: $padding;
    > .btn {
        align-self: stretch;
    }
}
</style>
