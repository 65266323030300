<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <container ref="container">
            <x-form
                ref="form"
                :rules="rules"
                :model="formData"
            >
                <template v-if="isOrderEditSendInfo && goodsSendMode !== 4 && shipType !== 2">
                    <form-item
                        label="发件人姓名:"
                        prop="sendConsignor"
                    >
                        <x-input
                            placeholder="请输入(可不填)"
                            v-model="formData.sendConsignor"
                            :disabled="!!orderId"
                        />
                    </form-item>
                    <form-item
                        label="发件人电话:"
                        prop="sendTel"
                    >
                        <x-input
                            placeholder="请输入(可不填)"
                            v-model="formData.sendTel"
                            :disabled="!!orderId"
                        />
                    </form-item>
                </template>
                <form-item
                    class="npa-a"
                    prop="shipInfo"
                >
                    <ShipMethod
                        ref="shipMethod"
                        :ship-method="orderData.shipMethod"
                        :goods-send-mode="goodsSendMode"
                        v-model="shipInfo"
                        :disabled="!!orderId"
                    />
                </form-item>
                <template v-if="goodsSendMode===4 && +orderData.shipMethod===1">
                    <form-item
                        label="付款人姓名:"
                        prop="subscriberName"
                        key="subscriberName"
                    >
                        <x-input
                            placeholder="请输入..."
                            v-model="formData.subscriberName"
                            :disabled="!!orderId"
                        />
                    </form-item>
                    <form-item
                        label="付款人身份证号:"
                        prop="subscriberIdCardNo"
                        key="subscriberIdCardNo"
                    >
                        <x-input
                            placeholder="请输入..."
                            v-model="formData.subscriberIdCardNo"
                            :disabled="!!orderId"
                        />
                    </form-item>
                </template>
                <form-item
                    v-if="goodsSendMode!==1 && goodsSendMode!==4 && +orderData.shipMethod===1"
                    label="身份证号:"
                    prop="deliveryIdCardNo"
                    key="deliveryIdCardNo"
                >
                    <IdCardValid
                        ref="IdCardValid"
                        :goods-send-mode="goodsSendMode"
                        :delivery-id="address.id"
                        :delivery-consignee="formData.deliveryConsignee"
                        v-model="formData.deliveryIdCardNo"
                        :disabled="!!orderId"
                    />
                </form-item>
                <form-item
                    label="买家留言:"
                    prop="buyerMsg"
                >
                    <x-input
                        type="textarea"
                        :auto-size="{maxRows:4}"
                        placeholder="请输入"
                        v-model="formData.buyerMsg"
                        :disabled="!!orderId"
                    />
                </form-item>
            </x-form>
            <list class="ma-t">
                <OrderGoodsItem
                    class="bc-w"
                    v-for="(item) in goodsList"
                    :key="item.id"
                    :data="item"
                    :is-premium="shipType!==2"
                    v-model="formData.premiumSkuIds"
                />
                <list-item
                    label="商品金额"
                >
                    <div
                        class="extra price"
                        slot="extra"
                    >
                        {{ goodsAmount | price }}
                    </div>
                </list-item>
                <list-item
                    v-if="goodsPremiumAmount"
                    label="物流保价："
                >
                    <div
                        class="extra price"
                        slot="extra"
                    >
                        {{ goodsPremiumAmount | price }}
                    </div>
                </list-item>
                <list-item
                    v-if="freight"
                    label="运费"
                >
                    <div
                        class="extra"
                        slot="extra"
                    >
                        <span
                            class="pa-l"
                            :class="isNaN(freight) ?'tc-red': 'price'"
                        >
                            <template
                                v-if="isNaN(freight)"
                            >{{ freight }}</template>
                            <template
                                v-else
                            >{{ freight | price }}</template>
                        </span>
                    </div>
                </list-item>
                <list-item
                    v-if="reduceAmount"
                    label="立减"
                >
                    <div
                        class="extra tc-price"
                        slot="extra"
                    >
                        - <span class="price">{{ reduceAmount | price }}</span>
                    </div>
                </list-item>
                <list-item
                    v-if="fullGiveDesc"
                    label="满赠"
                >
                    <div
                        class="extra"
                        slot="extra"
                    >
                        {{ fullGiveDesc }}
                    </div>
                </list-item>
            </list>
            <list class="ma-t">
                <list-item
                    v-if="$hasPower(['coupon:use'])"
                    class="link npa-t npa-b"
                >
                    <coupon-picker
                        class="label"
                        slot="label"
                        label="优惠券"
                        :uuid="uuid"
                        :disabled="!!orderId"
                        v-model="couponData"
                    >
                        <!--<div
                            class="extra"
                            slot="extra"
                        >
                            <div v-if="!couponData.id && orderData.couponNum > 0">
                                <div class="badge badge-red plane">有 {{ orderData.couponNum }} 张可用</div>&nbsp;
                                <span class="tc-red">去使用&nbsp;</span>
                            </div>
                            <div
                                class="details"
                                v-else-if="orderData.couponNum === 0">无可用</div>
                            <div v-else>- <span class="price">{{ couponData.couponAmount }}</span></div>
                        </div>-->
                    </coupon-picker>
                </list-item>

                <list-item
                    v-if="$hasPower(['giftCard:use'])"
                    class="link npa-t npa-b"
                >
                    <GiftCardPicker
                        class="label"
                        slot="label"
                        label="礼品卡"
                        :uuid="uuid"
                        :deductible-amount="totalAmount"
                        :disabled="!!orderId"
                        v-model="giftCard"
                    >
                        <!--<div
                            class="extra"
                            slot="extra"
                        >
                            <div v-if="!couponData.id && orderData.couponNum > 0">
                                <div class="badge badge-red plane">有 {{ orderData.couponNum }} 张可用</div>&nbsp;
                                <span class="tc-red">去使用&nbsp;</span>
                            </div>
                            <div
                                class="details"
                                v-else-if="orderData.couponNum === 0">无可用</div>
                            <div v-else>- <span class="price">{{ couponData.couponAmount }}</span></div>
                        </div>-->
                    </GiftCardPicker>
                </list-item>

                <!-- 积分模块-->
                <template v-if="pointCase.isAvailable">
                    <template
                        v-if="orderTotalAmount >= pointCase.minOrderAmount"
                    >
                        <list-item>
                            <div
                                class="label"
                                slot="label"
                            >
                                共 {{ pointCase.validPoint }} 积分, 可用 {{ maxAvailablePoint }},抵 {{ maxAvailablePoint * this.pointProportion }} 元
                            </div>
                            <div
                                class="extra"
                                slot="extra"
                            >
                                <x-switch v-model="isUserPoint" />
                            </div>
                        </list-item>
                        <list-item
                            v-show="isUserPoint"
                            :label="`${pointCase.consumptionPoints} 积分 = ${pointCase.deductibleAmount}`"
                        >
                            <div
                                class="extra"
                                slot="extra"
                            >
                                - <span class="price">{{ pointQuantity * pointProportion }}</span>
                                <i
                                    class="f-icon tc-red ma-r"
                                    @click="onPointDesc"
                                >&#xf040;</i>
                                <input-number
                                    no-input
                                    :min="0"
                                    :max="maxAvailablePoint"
                                    :step="pointCase.consumptionPoints"
                                    v-model="pointQuantity"
                                />
                            </div>
                        </list-item>
                    </template>
                    <list-item
                        v-else
                    >
                        <div
                            class="label"
                            slot="label"
                        >
                            共 {{ pointCase.validPoint }} 积分, 本单小于 <span class="tc-red">${{ pointCase.minOrderAmount | price }}</span> 不可用
                        </div>
                        <div
                            class="extra"
                            slot="extra"
                        >
                            <i
                                class="f-icon tc-red ma-l"
                                @click="onPointDesc"
                            >&#xf041;</i>
                        </div>
                    </list-item>
                </template>
            </list>
        </container>
        <div
            class="second-footer free-freight"
            v-if="freeFreight"
        >
            本次订单再购 <span class="tc-red">{{ freeFreight }}</span> 商品免运费
        </div>
        <div class="footer new-order">
            <div class="total-amount">
                合计：<span class="fs-big price">{{ totalAmount | price }}</span>
            </div>
            <div
                class="btn btn-main"
                @click="()=>{onSubmit()}"
            >
                提交订单
            </div>
            <pay-popup
                ref="payPopup"
                has-back
                :order-type="orderType"
                :order-ids="orderId"
                :module="1"
            />
        </div>
        <x-dialog
            ref="freightDialog"
            label="抱歉，以下商品无该地址配送服务"
            body-content="body"
        >
            <list>
                <OrderGoodsItem
                    class="bc-w"
                    v-for="(item) in unsupportedAreaGoodsList"
                    :key="item.id"
                    :data="item"
                />
            </list>
            <div
                class="footer bor-t"
                slot="footer"
            >
                <div
                    class="btn"
                    @click="onSelectAddress"
                >
                    选择地址
                </div>
                <div
                    class="btn btn-red"
                    @click="onCancelOrder"
                >
                    取消
                </div>
            </div>
        </x-dialog>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
import { Buyer } from '@/api';

export default {
    name: 'OrderNew',
    mixins: [pageMixin],
    data() {
        return {
            shipInfo: {},
            freightData: {
                // freightAmount：运费金额
                // freightMode: 1:全场包邮；2：固定运费；3：使用运费模板
                // unsupportedAreaGoodsIdList 不满足条件的商品id
            },
            orderData: {},
            goodsList: [],
            goodsAmount: 0,
            orderAmount: 0,
            reduceAmount: 0,
            fullGiveDesc: 0,
            goodsDiscountAmount: 0,
            orderType: 100,
            pointCase: {},
            couponData: {},
            giftCard: {},
            isUserPoint: false,
            pointQuantity: 0,
            goodsSendMode: 0,
            isOrderEditSendInfo: false,
            formData: {
                shipInfo: '',
                buyerMsg: '',
                deliveryConsignee: '',
                deliveryPhone: '',
                deliveryRegionId: '',
                deliveryAddress: '',
                deliveryPostcode: '',
                premiumSkuIds: [],
                deliveryIdCardNo: '',
                sendConsignor: '',
                sendTel: '',
                subscriberName: '',
                subscriberIdCardNo: '',
            },
            rules: {
                shipInfo: {
                    validator: this.validShipInfo,
                    // message: '请选择收货地址',
                },
                subscriberName: {
                    required: true,
                    message: '不能为空',
                },
                subscriberIdCardNo: [
                    {
                        required: true,
                        message: '不能为空',
                    },
                    {
                        validator: this.$utils.Valid.idCard,
                    },
                ],
            },
            orderId: 0, // 生成订单后返回的订单 id
            isMounted: false,
        };
    },
    computed: {
        uuid() {
            return this.$route.params.uuid;
        },
        $$shipMethod() {
            return this.$refs.shipMethod || null;
        },
        $$addressPicker() {
            return this.$$shipMethod.$$addressPicker || null;
        },
        $$freightDialog() {
            return this.$refs.freightDialog || null;
        },
        $$payPopup() {
            return this.$refs.payPopup || null;
        },
        address() {
            return this.shipInfo.address || {};
        },
        shipType() {
            return this.shipInfo.shipType || 0;
        },
        goodsMap() {
            const res = {};
            this.goodsList.forEach(item => {
                res[item.goodsId] = item;
            });
            return res;
        },
        unsupportedAreaGoodsList() {
            const unGoodsIds = this.freightData.unsupportedAreaGoodsIdList;
            if (!unGoodsIds || !unGoodsIds.length) return [];
            const res = [];
            unGoodsIds.forEach(item => {
                res.push(this.goodsMap[item]);
            });
            return res;
        },
        freeFreight() {
            const { freightData } = this;
            if (!this.address.consignee || this.shipType !== 1) return 0;
            const mode = freightData.freightMode;
            if (mode !== 2) return 0;
            const res = freightData.freeFreightOrderAmount - this.goodsAmount;
            return res <= 0 ? 0 : res;
        },
        freight() {
            const { freightData } = this;
            if (!this.address.consignee || this.shipType !== 1) return '';
            const mode = freightData.freightMode;
            const freight = freightData.freightAmount;
            // const freeFreightOrderAmount = this.address.freeFreightOrderAmount;
            switch (mode) {
                case 1:
                    return '全场包邮';
                case 2: // 满多少包邮
                case 3: // 使用运费模板
                    return !+freight ? '免运费' : +freight === -1 ? '无法配送' : freight;
                default:
                    return '';
            }
        },
        goodsSkuIdMap() {
            return this.goodsList.reduce((p, c) => ((p[c.skuId] = c), p), {});
        },
        goodsPremiumAmount() {
            const {
                formData: { premiumSkuIds },
                shipType,
            } = this;
            if (!premiumSkuIds || !premiumSkuIds.length || shipType === 2) return 0;
            return premiumSkuIds.reduce((pre, curr) => {
                const item = this.goodsSkuIdMap[curr];
                return pre + item.goodsPremiumAmount * item.quantity;
            }, 0);
        },
        totalAmount() {
            const isUserPoint =
                this.pointCase.isAvailable &&
                this.isUserPoint &&
                this.orderTotalAmount >= this.pointCase.minOrderAmount;
            const total = isUserPoint
                ? this.orderTotalAmount - this.pointQuantity * this.pointProportion
                : this.orderTotalAmount;
            console.log(total + this.goodsPremiumAmount);
            return total + this.goodsPremiumAmount;
        },
        /**
         * 订单合计 = 订单金额 + 运费 - 优惠券
         * @returns {number}
         */
        orderTotalAmount() {
            const freight = isNaN(this.freight) ? 0 : this.freight;
            return this.orderAmount + freight - (this.couponData.id ? this.couponData.discountAmount : 0);
        },
        /**
         * 积分/金额
         */
        pointProportion() {
            const {
                consumptionPoints, // 消耗积分
                deductibleAmount, // 抵扣金额
            } = this.pointCase;
            return deductibleAmount / consumptionPoints;
        },
        /**
         * 最大可用积分 = min(有效积分, parseInt(订单合计 * 最大比率 / 抵扣金额) * 消耗积分)
         */
        maxAvailablePoint() {
            const {
                validPoint, // 有效积分
                consumptionPoints, // 消耗积分
                deductibleAmount, // 抵扣金额
                maxProportion, // 最大比率
            } = this.pointCase;
            const {
                orderTotalAmount, // 订单合计
            } = this;

            return Math.min(
                validPoint,
                parseInt((orderTotalAmount * maxProportion) / deductibleAmount + '') * consumptionPoints,
            );
        },
        addressChange() {
            const { address, isMounted } = this;
            if (!isMounted) return false;
            return `${address.consignee || ''}${address.id || ''}`;
        },
        isCart() {
            return this.$route.query.type === 'cart';
        },
    },
    watch: {
        shipInfo(val, oldVal) {
            if (val === oldVal) return;
            this.$$form.clearValidate();
            this.formData.shipInfo = val;
        },
        addressChange: {
            handler(val, oldVal) {
                if (!val || val === oldVal) return;
                const { address } = this;
                if (address.consignee) {
                    // this.formData.deliveryAddressId = val.id;
                    this.formData.deliveryConsignee = address.consignee;
                    this.formData.deliveryPhone = address.mobile;
                    this.formData.deliveryRegionId = address.regionId;
                    this.formData.deliveryAddress = address.address;
                    this.formData.deliveryPostcode = address.postcode;
                    this.formData.deliveryIdCardNo = address.idCardNo || '';
                    this.$nprogress.start();
                    this.$api.Buyer.Sp.ShopFreightTemplate.getOrderFreightInfo({
                        uuid: this.uuid,
                        deliveryRegionId: address.regionId,
                    }).then(json => {
                        this.$nprogress.done();
                        this.freightData = json.data.data;
                        if (this.freightData.freightAmount === -1) {
                            this.$nextTick(() => {
                                this.$$freightDialog.open({
                                    label: this.freightData.errorMsg,
                                });
                            });
                        }
                    });
                } else {
                    this.$nextTick(() => {
                        this.$$addressPicker.open(address.id ? 1 : 2);
                    });
                }
            },
            immediate: true,
        },
    },
    beforeRouteEnter(to, from, next) {
        let fn = 'buyNowConfirm';
        if (to.query.type) {
            fn = 'cartConfirm';
        }
        Buyer.Ex.Order[fn]({ uuid: to.params.uuid })
            .then(json => {
                const res = json.data.data;
                next(vm => {
                    vm.orderData = res;
                    vm.$set(vm.shipInfo, 'address', res.address || {});
                    if (res.defaultShopStore) vm.$set(vm.shipInfo, 'shop', res.defaultShopStore || {});
                    vm.goodsList = res.goodsList;
                    vm.goodsSendMode = res.goodsSendMode;
                    vm.goodsAmount = res.goodsAmount;
                    vm.orderAmount = res.orderAmount;
                    vm.reduceAmount = res.reduceAmount;
                    vm.fullGiveDesc = res.fullGiveDesc;
                    vm.pointCase = res.pointCase;
                    vm.goodsDiscountAmount = res.goodsDiscountAmount;
                    vm.orderType = res.orderType;
                    vm.isOrderEditSendInfo = res.isOrderEditSendInfo;
                    vm.formData.premiumSkuIds = res.goodsList.reduce((pre, curr) => {
                        if (curr.goodsPremiumAmount) {
                            pre.push(curr.skuId);
                        }
                        return pre;
                    }, []);
                    vm.formData.sendConsignor = res.sendConsignor;
                });
            })
            .catch(() => {
                next();
            });
    },
    methods: {
        validShipInfo(rule, value, callback) {
            const { shipType, address, shop } = value;
            if (shipType === 1 && (!address || !address.consignee)) {
                callback(new Error('请选择收货地址'));
            } else if (shipType === 2 && (!shop || !shop.id)) {
                callback(new Error('请选择自取门店'));
            }
            callback();
        },
        async onSubmit() {
            if (this.orderId) {
                this.onPay(this.orderId);
                return;
            }
            if (this.shipType === 1 && this.freightData.freightAmount === -1) {
                this.$$freightDialog.open();
                return;
            }

            await this.$$form.validate();
            if (this.goodsSendMode !== 1 && this.goodsSendMode !== 4) {
                try {
                    await this.$refs['IdCardValid'].validate();
                } catch (e) {
                    await this.$messageBox.alert(
                        '当前订单未进行身份证信息认证，为确保及时发货请在付款完成后尽快进行身份认证',
                    );
                }
            }

            const data = {
                ...this.formData,
                uuid: this.uuid,
            };
            // 保费数据类型转换
            data.premiumSkuIds = data.premiumSkuIds.join(',');
            const { shipInfo } = this;
            const { shipType, address, shop, pickupDate, storeServiceTimeId } = shipInfo;
            data.shipMethod = shipType;
            if (shipType === 1) {
                if (!address || !address.consignee) {
                    this.$messageBox.tips('请选择收货地址');
                    return;
                }
            } else if (shipType === 2) {
                if (!shop || !shop.id) {
                    this.$messageBox.tips('请选择门店');
                    return;
                }
                if (!pickupDate) {
                    this.$messageBox.tips('请选择取货日期');
                    return;
                }
                if (!storeServiceTimeId) {
                    this.$messageBox.tips('请选择取货时间');
                    return;
                }
                data.pickupDate = pickupDate;
                data.storeServiceTimeId = storeServiceTimeId;
            }

            if (this.isUserPoint && this.pointQuantity) {
                data.usePoint = this.pointQuantity;
            }
            if (this.couponData.id) {
                data.couponId = this.couponData.id;
            }
            if (this.giftCard.id) {
                data.giftCardId = this.giftCard.id;
            }

            this.$api.Buyer.Ex.Order.createOrder(data).then(json => {
                // const res = json.data;
                this.orderId = json.data.data;
                this.onPay(this.orderId);
                if (this.isCart) {
                    this.$store.dispatch('countInfo/updated');
                }
                /* this.$messageBox.confirm('订单已生成是否立即支付。', '温馨提示', {
                        cancelBtnText: '继续购买',
                        confirmBtnText: '立即支付',
                    }).then((action) => {
                        this.onPay(res.data);
                    }).catch((_err) => {
                        this.$router.go(-1);
                    }); */
            });
        },
        onPay() {
            this.$$payPopup.open();
        },
        onPointDesc() {
            this.$messageBox.alert(
                `<div class="ta-l">
                                            <div class="fw-bold">使用条件</div>
                                            <p>1.订单金额大于 ${this.currency} ${
                    this.pointCase.minOrderAmount
                }（含）；</p>
                                            <p>2.积分数量大于 ${this.pointCase.consumptionPoints}（含）；</p>
                                            <p>3.积分支付不得超过每笔订单应付金额的 ${this.pointCase.maxProportion *
                                                100}%；</p>
                                            <br>
                                            <div class="fw-bold">使用数量</div>
                                            <p>1.使用积分数量为 ${this.pointCase.consumptionPoints} 的整数倍；</p>
                                            <p>2.${this.pointCase.consumptionPoints} 积分可抵 ${this.currency} ${
                    this.pointCase.deductibleAmount
                }；</p>
                                        </div>`,
                '积分使用规则',
            );
        },
        onSelectAddress() {
            this.$$freightDialog.done().then(() => {
                console.log(this.$$addressPicker);
                this.$$addressPicker.open();
            });
        },
        onCancelOrder() {
            this.$$freightDialog.done().then(() => {
                // this.$router.go(-1);
            });
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.isMounted = true;
        });
    },
};
</script>

<style lang="scss">
.footer.new-order {
    background-color: #fff;
    > .total-amount {
        flex: 2 2 1%;
        text-align: right;
        padding-right: $padding-small;
    }
}

.free-freight {
    text-align: center;
    min-height: inherit !important;
    padding: $padding-small;
    background-color: mix($color-red, #fff, 40%);
    color: #fff;
}
</style>
