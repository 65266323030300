<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <container ref="container">
            <list class="ma-bor-b">
                <list-item
                    label="付款状态: "
                >
                    <div
                        class="extra"
                        slot="extra"
                    >
                        <span class="badge badge-red">{{ detail.paymentStatusName }}</span>
                    </div>
                </list-item>
                <list-item
                    label="付款时间: "
                    :extra="detail.paymentTime|dateTime"
                />
            </list>
            <list
                class="ma-bor-b"
                v-if="detail.paymentStatus !==2"
            >
                <list-item
                    label="审核时间: "
                    :extra="detail.payAuditTime|dateTime"
                />
                <list-item
                    label="审核备注: "
                    :extra="detail.payAuditComments"
                />
            </list>
            <list>
                <list-item
                    label="付款户名: "
                    :extra="detail.payer"
                />
                <list-item
                    class="reverse"
                    extra="付款凭证: "
                >
                    <img-list
                        class="label"
                        slot="label"
                        :data="detail.payVoucherUrlList"
                    />
                </list-item>
                <list-item
                    label="付款备注: "
                    :extra="detail.paymentComments"
                />
            </list>
        </container>
        <div class="footer">
            <div
                class="btn btn-main"
                @click="$router.go(-1)"
            >
                返回
            </div>
        </div>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
import { Buyer } from '@/api';

export default {
    name: 'OfflineDetail',
    mixins: [pageMixin],
    data() {
        return {
            detail: {},
        };
    },
    beforeRouteEnter(to, from, next) {
        const orderId = to.params.orderId;
        Buyer.Ex.Order.offlinePaymentDetail({ orderId }).then(json => {
            const res = json.data.data;
            next(vm => {
                vm.detail = res;
            });
        });
    },
    computed: {
        orderId() {
            return this.$route.params.orderId;
        },
    },
    methods: {},
};
</script>

<style lang="scss">
</style>
