<!-- Created by henian.xu on 2018/6/1. -->

<template>
    <!--v-if="+data.orderStatus===10"-->
    <div class="order-status">
        <div class="inner">
            <div>
                <!--0：全部；10：待付款；20：待发货；30：已发货；99：已取消；100：已完成-->
                <i 
                    class="f-icon fs-big" 
                    v-if="+data.orderStatus===10"
                >&#xf050;</i>
                <i 
                    class="f-icon fs-big" 
                    v-else-if="+data.orderStatus===20"
                >&#xf051;</i>
                <i 
                    class="f-icon fs-big" 
                    v-else-if="+data.orderStatus===30"
                >&#xf052;</i>
                <i 
                    class="f-icon fs-big" 
                    v-else-if="+data.orderStatus===99"
                >&#xf054;</i>
                <i 
                    class="f-icon fs-big" 
                    v-else-if="+data.orderStatus===100"
                >&#xf053;</i>
                {{ data.orderStatusName }}
            </div>
            <div class="badge badge-red fs-small">
                {{ data.shipMethod===1?'送':'取' }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OrderStatus',
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.order-status {
    padding: $padding $padding 0;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    font-size: 0.36rem;
    > .inner {
        background-color: $color-second;
        flex: 1 1 1%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $padding $padding-big;
        border-top-left-radius: 0.1rem;
        border-top-right-radius: 0.1rem;
        min-height: 1rem;
        > .f-icon {
            margin-right: $margin-small;
            line-height: 1;
        }
    }
}
</style>
