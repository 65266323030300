<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <container ref="container">
            <panel
                label="付款信息"
            >
                <div
                    class="body npa-a"
                    slot="body"
                >
                    <x-form
                        ref="form"
                        :model="formData"
                        :rules="rules"
                    >
                        <form-item
                            label="支付金额: "
                        >
                            <div style="padding-left: .1rem;">
                                <span class="fs-big price">{{ paymentAmount }}</span>
                            </div>
                        </form-item>
                        <form-item
                            label="付款户名: "
                            prop="payer"
                        >
                            <x-input
                                v-model="formData.payer"
                            />
                        </form-item>
                        <form-item
                            label="付款凭证: "
                            prop="payVouchers"
                        >
                            <upload
                                class="npa-a"
                                action="/rpc/comm/rs/attachment/uploadOrderPaymentImage"
                                accept="image/*"
                                v-model="formData.payVouchers"
                                :column="4"
                            />
                        </form-item>
                        <div class="pa-a tc-red fs-small">
                            （注：请将转账后的电子凭证截图后上传）
                        </div>
                        <form-item
                            label="付款备注: "
                        >
                            <x-input
                                type="textarea"
                                :auto-size="{minRows:2,maxRows:5}"
                                v-model="formData.paymentComments"
                            />
                        </form-item>
                    </x-form>
                </div>
            </panel>
            <Widget
                :id="6"
                no-show-complete
            />
        </container>
        <div class="footer">
            <div
                class="btn btn-main"
                @click="onSubmit"
            >
                提交
            </div>
        </div>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';

export default {
    name: 'OfflinePay',
    mixins: [pageMixin],
    data() {
        return {
            formData: {
                payer: '',
                payVouchers: [],
                paymentComments: '',
            },
            rules: {
                payer: {
                    required: true,
                    message: '付款人不能为空',
                },
                payVouchers: {
                    type: 'array',
                    min: 1,
                    required: true,
                    message: '付款凭证不能为空',
                },
            },
        };
    },
    computed: {
        module() {
            return this.$route.params.module;
        },
        orderIds() {
            return this.$route.params.orderIds;
        },
        paymentAmount() {
            return this.$route.query.paymentAmount || 0;
        },
    },
    methods: {
        onSubmit() {
            this.$$form.validate().then(() => {
                const payVoucher = this.formData.payVouchers.reduce((prev, next) => {
                    prev.push(next.name);
                    return prev;
                }, []);
                const data = {
                    ...this.formData,
                    module: this.module,
                    orderIds: this.orderIds,
                    payVoucher: payVoucher.join(','),
                };
                this.$api.Buyer.Ex.Pay.payforOffline(data).then(() => {
                    this.$router.replace(`/buyer/offlinePaymentList`);
                });
            });
        },
    },
};
</script>

<style lang="scss">
</style>
